import dynamic from 'next/dynamic';
import Box from '@mui/material/Box';
import Typography  from '@mui/material/Typography'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Link from 'next/link';
import { useSelector } from 'react-redux';
import { trimSp } from '@components/config/helpers';
import usePricingModel from '@components/_hooks/usePricingModel';

const DynamicSwitch = dynamic(() => import("../Reusables/Components/DynamicSwitch"), {ssr: false});
const BoxContainer = dynamic(() => import("@components/Components/Reusables/BoxContainerV2"));
const InfoTooltip = dynamic(() => import("../Reusables/Tooltips/InfoTooltip"), {ssr: false});
const StyledBox = dynamic(() => import("@components/Custom/Box/StyledBox"));

const styles = {
	cursor: 'pointer',
    marginLeft: '5px',
   	marginTop: '1px'
}

const HeaderBar1 = () => {

	const global_settings = useSelector(state => state.globalReducer.global_settings);
	const {pricing_model, setPricingModel, showPricingModel, disabledToggle} = usePricingModel();
	
	return (
		<Box 
			
			sx={{
				...(showPricingModel ? {
					minHeight: '38px'
				} : {
					pt: 0.5, 
					pb: 0.5,
				}),
				color:'primary',
				textAlign:'start', 
				backgroundColor: 'primary.main'/*alpha('#fff', 0.5)*/, 
			}}
		>
			<BoxContainer className={'d-flex justify-space-between'}>
				<Box className="text-vertical">
					<Link href={`tel:${trimSp(global_settings.sales_telephone)}`}>
						<StyledBox className='text-vertical' sx={{color:'#fff',  display: 'flex', flexDirection:'row',}}>
							<LocalPhoneIcon size={10}/>
							<Typography sx={styles} className={`f-medium`} variant={'span'}>{global_settings.sales_telephone}</Typography>
						</StyledBox>
					</Link>
				</Box>
				{
					showPricingModel && (
						<div className="d-flex">
							<DynamicSwitch 
								labels={[<>NON-EXHIBITION <span className="white font-light">pricing</span></>, <>EXHIBITION <span className="font-light white">(all inclusive) pricing</span></>]}
								value={pricing_model === 1}
								onChange={(e) => setPricingModel(e.target.checked ? 1 : 0)}
								onColor="white.main" 
								offColor="#ffff00"
								typohraphyClassNames="fs-18"
								disabled={disabledToggle}
							/>
							<InfoTooltip color="#ffff00" title="Price includes delivery, build, takedown &amp; collection" />
						</div>
					)
				}
				<Box className="text-vertical">
					<Link href={'/tracking'}>
						<StyledBox className='text-vertical' sx={{color:'#fff',  display: 'flex', flexDirection:'row', marginRight: 1.4}}>
							<LocationOnIcon size={10}/>
							<Typography sx={styles} className={`f-medium`} variant={'span'} >
							Track My Order
							</Typography>
						</StyledBox>
					</Link>
				</Box>
			</BoxContainer>
		</Box>
	)
}

export default HeaderBar1;