import CacheFactory from '@components/Factories/CacheFactory';
import actions from '@components/Redux/Global/actions';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useGlobalSettings from './useGlobalSettings';

const disallowed_routes = ['/checkout/quote', '/checkout/delivery', '/checkout/payment', '/checkout/success'];

const usePricingModel = () => {
    const pricing_model = useSelector((state) => state.globalReducer.pricing_model);
    const dispatch = useDispatch();
    const {getSetting} = useGlobalSettings();
    const router = useRouter();

    const disabledToggle = useMemo(() => disallowed_routes.includes(router.route), [router.route])

    useEffect(() => {
        if(CacheFactory.has('pricing_model') && pricing_model != CacheFactory.get('pricing_model')){
            setPricingModel(CacheFactory.get('pricing_model'));
        }
    }, [])
    
    const setPricingModel = (value, force = false) => {
        if(disabledToggle && force != true) return;
        
		dispatch({
			type: actions.SET_GLOBAL_DATA,
			payload: {
				pricing_model: value
			}
		})
        CacheFactory.put('pricing_model', value);
	}

    const showPricingModel = getSetting('exhibition_pricing_model', 'no') == 'yes'

    return {pricing_model, setPricingModel, showPricingModel, disabledToggle}
}

export default usePricingModel;